<template>
  <app-button
    :color="color"
    :href="callScheduleUrl"
    :size="size"
    :variant="variant"
    @click="onClick"
  >
    <slot />
  </app-button>
</template>

<script lang="ts" setup>
import type {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
} from "@pollen/ui-library/client/shared/button/button.model";

import type { CallRequestOrigin } from "~/business-areas/analytics/analytics.model";

const properties = defineProps<{
  id: CallRequestOrigin;
  size?: ButtonSize;
  color?: ButtonColor;
  variant?: ButtonVariant;
}>();

const {
  public: {
    hubspot: { callScheduleUrl },
  },
} = useRuntimeConfig();

const { $analytics } = useNuxtApp();

const onClick = () => {
  $analytics.track("call_requested", { origin: properties.id });
};
</script>
